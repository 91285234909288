<template>
    <v-row v-if="team">
        <v-col cols="12" md="10" offset-md="1">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <sprint-header
                            :team="team"
                            :sprint="sprint"
                            :total="openIssues.length + closeIssues.length"
                            :close="closeIssues.length"
                            :totalTimes="openTimes + closeTimes"
                            :closeTimes="closeTimes"
                            :estimatedTimes="time"
                            :color="sprintColor"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-tabs centered :color="sprintColor" v-model="tab">
                                    <v-tab v-if="sprint.status === 'close'">
                                        {{ $t('teams.sprints.menu.review') }}
                                    </v-tab>
                                    <v-tab v-for="v in views" :key="v.id">
                                        {{ v.label }}
                                    </v-tab>

                                    <v-tab-item v-if="sprint.status === 'close'">
                                        <sprint-review :team="team" :sprint="sprint" />
                                    </v-tab-item>
                                    <v-tab-item v-for="v in views" :key="v.id">
                                        <sprint-kanban
                                            v-if="v.name === 'kanban'"
                                            :team="team"
                                            :sprint="sprint"
                                            :view="v"
                                            v-on:update-open-issues="updateOpenIssues"
                                            v-on:update-close-issues="updateCloseIssues"
                                            :color="sprintColor"
                                        />
                                        <sprint-issues
                                            v-if="v.name === 'IssuesInSprint'"
                                            :team="team"
                                            :sprint="sprint"
                                            :view="v"
                                            :outSprint="v.name === 'outSprint'"
                                            v-on:update-open-issues="updateOpenIssues"
                                            v-on:update-close-issues="updateCloseIssues"
                                        />
                                        <sprint-issues
                                            v-else
                                            :team="team"
                                            :sprint="sprint"
                                            :view="v"
                                            :outSprint="v.name === 'outSprint'"
                                        />
                                    </v-tab-item>
                                </v-tabs>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderBy from 'lodash/orderBy';
import SprintHeader from './sprint/header';
import SprintKanban from './sprint/kanban';
import SprintIssues from './sprint/issues';
import SprintReview from './sprint/review';

export default {
    name: 'team-sprint',
    data: () => ({
        openIssues: [],
        closeIssues: [],
        tab: undefined,
    }),
    components: {
        SprintHeader,
        SprintKanban,
        SprintIssues,
        SprintReview,
    },
    created() {
        if (!this.$route.params.sprint_id) {
            const sprint = this.team.sprints.find((s) => s.status === 'active');
            this.$router.push({
                name: 'team-sprint',
                params: { sprint_id: sprint.id },
            });
        }
    },
    computed: {
        ...mapGetters(['team']),
        sprint() {
            return this.team.sprints.find((s) => s.id === this.$route.params.sprint_id);
        },
        sprintColor() {
            if (this.sprint.status === 'active') {
                return 'cyan';
            }
            if (this.sprint.status === 'close') {
                return 'blue-grey';
            }

            return 'light-blue';
        },
        views() {
            return OrderBy(this.team.views, ['group'], ['asc']);
        },
        openTimes() {
            return this.openIssues
                .map((i) => i.tracking)
                .reduce((a, b) => a.concat(b), [])
                .map((t) => t.time)
                .reduce((a, b) => (a = a + b), 0);
        },
        closeTimes() {
            return this.closeIssues
                .map((i) => i.tracking)
                .reduce((a, b) => a.concat(b), [])
                .map((t) => t.time)
                .reduce((a, b) => (a = a + b), 0);
        },
        time() {
            const open = this.openIssues.map((i) => i.time).reduce((a, b) => (a = a + b), 0);
            const close = this.closeIssues.map((i) => i.time).reduce((a, b) => (a = a + b), 0);

            return open + close;
        },
    },
    methods: {
        openView(v) {
            this.activeView = v;
        },
        updateOpenIssues(issues) {
            const idToRemove = issues.map((i) => i.id);
            this.openIssues = this.openIssues.filter((i) => !idToRemove.includes(i.id)).concat(issues);
        },
        updateCloseIssues(issues) {
            const idToRemove = issues.map((i) => i.id);
            this.closeIssues = this.closeIssues.filter((i) => !idToRemove.includes(i.id)).concat(issues);
        },
    },
};
</script>
