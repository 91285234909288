<template>
    <v-row>
        <v-col>
            <issues-groups :view.sync="view" :filters="filtersForIssues" v-on:update-group="updateGroup" />
            <issues-view
                :view="view"
                :filters="filtersForIssues"
                :group.sync="group"
                :allowEditing="false"
                :showFooter="false"
                :allowSelect="false"
                v-on:getted-issues="updateIssues"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import issuesGroups from '../../../issues/issue/groups';
import issuesView from '../../../issues/issue/view';

export default {
    name: 'team-sprint-issues',
    data: () => ({
        group: undefined,
    }),
    components: {
        issuesView,
        issuesGroups,
    },
    props: {
        team: {
            required: true,
        },
        sprint: {
            required: true,
        },
        view: {
            required: true,
        },
        outSprint: {
            default: false,
        },
    },
    computed: {
        ...mapGetters(['status', 'profile']),
        filtersForIssues() {
            if (this.outSprint) {
                return [
                    {
                        field: 'tracking.date',
                        operator: '$gte',
                        value: this.sprint.startedAt,
                    },
                    {
                        field: 'tracking.date',
                        operator: '$lt',
                        value: this.sprint.endedAt,
                    },
                    {
                        field: 'sprint',
                        operator: '$ne',
                        value: this.sprint.name,
                    },
                ];
            } else {
                return [
                    {
                        field: 'sprint',
                        operator: '$eq',
                        value: this.sprint.name,
                    },
                ];
            }
        },
    },
    methods: {
        updateGroup(group) {
            this.group = group;
        },
        updateIssues(issuesToAdd) {
            const statusClosed = this.team.kanban
                .filter((k) => k.close)
                .map((k) => k.status)
                .reduce((a, b) => a.concat(b), []);

            const closeIssues = issuesToAdd.filter((i) => statusClosed.includes(i.status));
            const openIssues = issuesToAdd.filter((i) => !statusClosed.includes(i.status));

            this.$emit('update-open-issues', openIssues);
            this.$emit('update-close-issues', closeIssues);
        },
    },
};
</script>
