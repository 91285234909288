<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col>
                    <h4 :class="`${color}--text text--${colorClass} headline text-h4`">
                        {{ sprint.name }}
                    </h4>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                    <h4 :class="`${color}--text text--${colorClass} overline text-h5`">
                        {{ formatDate(sprint.startedAt, 'dd MMM yyyy') }}
                        &nbsp;-&nbsp;
                        {{ formatDate(sprint.endedAt, 'dd MMM yyyy') }}
                    </h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col dense>
                    <v-progress-linear
                        rounded
                        :value.sync="advanceTimes"
                        :color="`${color} darken-3`"
                        height="50"
                        :dark="advanceTimes > 45"
                        :striped="advanceTimes < 100"
                    >
                        <template v-if="advanceTimes">
                            <strong>{{ advanceTimes }} %</strong>
                        </template>
                    </v-progress-linear>
                </v-col>
            </v-row>
            <v-row>
                <v-col dense>
                    <small>
                        {{ total }} {{ $t('issues.actions.total_issues') }} ({{ close }}
                        {{ $t('issues.actions.close_issues') }} - {{ total - close }}
                        {{ $t('issues.actions.open_issues') }})
                    </small>
                </v-col>
                <v-spacer></v-spacer>
                <v-col dense class="text-right">
                    <small>
                        {{ $t('teams.sprints.actions.real_times') }} {{ totalTimes }} h /
                        {{ $t('teams.sprints.actions.estimated_times') }} {{ estimatedTimes }} h
                    </small>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-spacer></v-spacer>
                <v-col dense class="text-right">
                    <small>
                        {{ $t('teams.sprints.actions.total_times') }} : {{ closeTimes }} h
                        {{ $t('teams.sprints.actions.close_times') }} - {{ totalTimes - closeTimes }} h
                        {{ $t('teams.sprints.actions.open_times') }}
                    </small>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions v-if="allowActions">
            <v-spacer />
            <v-btn
                v-if="sprint.status === 'active'"
                color="amber darken-2"
                text
                v-text="$t('teams.sprints.actions.close')"
                @click="closeSprint()"
            ></v-btn>
            <v-btn
                v-if="sprint.status === 'new'"
                color="blue darken-2"
                text
                v-text="$t('teams.sprints.actions.active')"
                @click="activeSprint()"
            ></v-btn>

            <v-dialog v-model="dialog" max-width="800px" persistent>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-if="sprint.status === 'active' || sprint.status === 'new'"
                        color="red darken-2"
                        text
                        v-text="$t('teams.sprints.actions.changeDate')"
                        v-on="on"
                    ></v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline blue--text text--darken-1">{{ $t('title.editSprint') }}</span>
                    </v-card-title>

                    <v-card-text>
                        <date-selector
                            v-model="sprintToUpdate.startedAt"
                            :label="$t('teams.sprints.attributes.startedAt')"
                        />

                        <date-selector
                            v-model="sprintToUpdate.endedAt"
                            :label="$t('teams.sprints.attributes.endedAt')"
                        />
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="cancelEdit">
                            {{ $t('buttons.cancel') }}
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="updateSprint">
                            {{ $t('buttons.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDateMixin } from '@/mixins/dates';
import dateSelector from '@/components/selectors/simple/date';
import { parseISO } from 'date-fns';

export default {
    name: 'team-sprint-header',
    data: () => ({
        sprintToUpdate: undefined,
        dialog: false,
    }),
    mixins: [formatDateMixin],
    props: {
        team: {
            required: true,
        },
        sprint: {
            required: true,
        },
        total: {
            default: 0,
        },
        close: {
            default: 0,
        },
        totalTimes: {
            default: 0,
        },
        closeTimes: {
            default: 0,
        },
        estimatedTimes: {
            default: 0,
        },
        color: {
            default: 'grey',
        },
    },
    components: { dateSelector },
    created() {
        this.sprintToUpdate = Object.assign({}, this.sprint);
        this.sprintToUpdate.startedAt = parseISO(this.sprintToUpdate.startedAt);
        this.sprintToUpdate.endedAt = parseISO(this.sprintToUpdate.endedAt);
    },
    computed: {
        ...mapGetters(['project', 'profile']),
        teamUser() {
            if (this.team) {
                return this.team.members.filter((m) => m.user === this.profile.name)[0];
            }
            return undefined;
        },
        allowActions() {
            if (this.teamUser) {
                return this.teamUser.roles.includes('productManager');
            }
            return false;
        },
        advanceTimes() {
            if (this.sprint.status === 'close') {
                return this.sprint.done;
            }
            return Math.round((this.closeTimes * 100) / this.totalTimes);
        },
        colorClass() {
            return this.$vuetify.theme.dark ? 'lighten-3' : 'darken-4';
        },
    },
    methods: {
        ...mapActions(['updateTeamSprint']),
        closeSprint() {
            this.updateTeamSprint({
                id: this.sprint.id,
                team: this.team.id,
                status: 'close',
                done: this.advanceTimes,
            });
            this.$emit('update-sprint');
        },
        activeSprint() {
            this.updateTeamSprint({
                id: this.sprint.id,
                team: this.team.id,
                status: 'active',
            });
            this.$emit('update-sprint');
        },
        cancelEdit() {
            this.dialog = false;
        },
        updateSprint() {
            this.updateTeamSprint({
                id: this.sprint.id,
                team: this.team.id,
                startedAt: this.sprintToUpdate.startedAt,
                endedAt: this.sprintToUpdate.endedAt,
            });
            this.$emit('update-sprint');
            this.dialog = false;
        },
    },
};
</script>

<style></style>
