<template>
    <v-card flat>
        <v-card-text>
            <markdown
                :value="sprint.review"
                v-on:save-content="changeAttribute"
                v-on:show-editor="showEmpty = !showEmpty"
                :label="$t('teams.sprints.actions.add_review')"
                :allow-editing="this.allowEditing"
            />
        </v-card-text>
        <v-card-text v-if="!sprint.review && showEmpty" class="subtitle-1 py-8 text-center">
            {{ $t('teams.sprints.empty.review') }}
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import markdown from '@/components/shared/markdown';
import { projectMemberMixin } from '@/mixins/member';

export default {
    name: 'team-sprint-review',
    data: () => ({
        editing: false,
        editingValue: undefined,
        showEmpty: true,
    }),
    mixins: [projectMemberMixin],
    components: {
        markdown,
    },
    props: {
        team: {
            required: true,
        },
        sprint: {
            required: true,
        },
        allowEdit: {
            default: true,
        },
    },
    computed: {
        ...mapGetters(['project']),
        teamUser() {
            if (this.team) {
                return this.team.members.filter((m) => m.user === this.profile.name)[0];
            }
            return undefined;
        },
        allowEditing() {
            return this.allowEdit && this.teamUser.roles.includes('productManager');
        },
    },
    methods: {
        ...mapActions(['updateTeamSprint']),
        changeAttribute(value) {
            this.updateTeamSprint({
                id: this.sprint.id,
                team: this.team.id,
                review: value,
            });
        },
    },
};
</script>

<style></style>
