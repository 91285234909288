<template>
    <v-container class="sprint-container" v-if="team">
        <v-row>
            <v-col v-for="k in groups" :key="k.id">
                <h5
                    :class="`${color}--text text--${colorClass} text-h5 overline text-center`"
                    v-text="`${k.label}`"
                ></h5>
            </v-col>
        </v-row>
        <v-row class="flex-nowrap">
            <template v-for="kanban in groups">
                <v-col class="sprint-col" :key="'col-' + kanban.id">
                    <issues-view
                        :view="view"
                        :filters="kanban.filters"
                        :allowEditing="false"
                        :display-card="true"
                        v-on:getted-issues="updateIssues($event, kanban)"
                        :showFooter="false"
                        :cols="['tracking', 'time']"
                    />
                </v-col>
                <v-divider vertical :key="'divider-' + kanban.id" />
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issuesView from '../../../issues/issue/view';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'team-sprint-kanban',
    data: () => ({}),
    components: {
        issuesView,
    },
    props: {
        team: {
            required: true,
        },
        sprint: {
            required: true,
        },
        view: {
            required: true,
        },
        color: {
            default: 'grey',
        },
    },
    computed: {
        ...mapGetters(['profile']),
        groups() {
            if (this.team) {
                const kanban = this.team.kanban.map((k) => {
                    return Object.assign(
                        {
                            filters: this.filtersForKanban(k),
                        },
                        k,
                    );
                });
                return OrderBy(kanban, ['order'], ['asc']);
            }
            return [];
        },
        colorClass() {
            return this.$vuetify.theme.dark ? 'lighten-3' : 'darken-4';
        },
    },
    methods: {
        updateIssues(issuesToAdd, k) {
            if (!k.close) {
                this.$emit('update-open-issues', issuesToAdd);
            } else {
                this.$emit('update-close-issues', issuesToAdd);
            }
        },
        filtersForKanban(kanban) {
            return [
                {
                    field: 'status',
                    operator: '$in',
                    value: kanban.status.join(','),
                },
                {
                    field: 'sprint',
                    operator: '$eq',
                    value: this.sprint.name,
                },
            ];
        },
    },
};
</script>

<style>
.sprint-container {
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
}

.sprint-container::-webkit-scrollbar {
    width: 15px;
}

.sprint-container::-webkit-scrollbar-track {
    background: #f3f2ef;
    border-left: 1px solid #dadada;
}

.sprint-container::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
}

.sprint-col {
    overflow-y: auto;
    max-height: 100vh;
    min-height: 100vh;
}

.sprint-col::-webkit-scrollbar {
    width: 15px;
}

.sprint-col::-webkit-scrollbar-track {
    background: #f3f2ef;
    border-left: 1px solid #dadada;
}

.sprint-col::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
}
</style>
